.testimonals-container {
  background-image: url(../../../assets/code-two.jpg);
  height: clamp(50px, 755 / 1600 * 100vw, 320px);
  background-size: cover;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $site-font;
}

.testimonals-one-container a {
  color: white;
}

.testimonals-two-container a {
  color: white;
}

.testimonals-three-container a {
  color: white;
}

.testimonals-four-container a {
  color: white;
}

.testimonals-container p {
  color: white;
  padding-left: 15%;
  padding-right: 15%;
}

.testimonals-container img {
  width: 7.5%;
  height: auto;
  border: solid white 2px;
  margin: 0 auto;
  border-radius: 90%;
}

.testimonals-container .slick-dots li button:before {
  color: white;
  font-size: 1rem;
}

.work-example {
  display: flex;
  flex-direction: column;
}

button.slick-arrow {
  display: none !important;
}
