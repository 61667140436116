.nav-container {
  text-align: center;
  font-family: $site-font;
}

.nav-bar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.name-logo {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 200%;
  border: 2px solid black;
  margin-top: 20px;
  padding-top: 2.5px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
}

.name-logo span {
  border-bottom: 5px solid black;
}

.nav-bar ul {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  padding-top: 5px;
  column-gap: 5%;
  white-space: nowrap;
  margin-left: -1%;
}

.nav-bar a {
  text-decoration: none;
  color: black;
}

.nav-bar li {
  list-style: none;
  text-transform: uppercase;
  padding-bottom: 40px;
  padding-top: 20px;
  width: 80px;
  display: inline-block;
}

.nav-bar button {
  background: transparent;
  font-family: $site-font;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.nav-bar button:hover {
  transform: scale(1.2);
  color: #0066cc;
}
