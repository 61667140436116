// skills-section //

@media (max-width: 740px) {
  .skills-title {
    width: 40% !important;
  }
}

// about-me-section //

@media (max-width: 1068px) {
  .about-me-container {
    flex-direction: column;
  }

  .about-text {
    width: 120%;
    padding-left: 50px;
    margin-left: -10%;
  }
}

@media (max-width: 500px) {
}

@media (max-width: 1068px) {
  .about-text button {
    margin-top: 20px;
  }
  .about-me-button {
    display: flex;
    justify-content: center;
  }
}

// testimonals section //

@media (max-width: 630px) {
  .testimonals-container p {
    font-size: 0.85rem !important;
  }
}

// contact-section //

@media (max-width: 1060px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-left-side {
    width: 100% !important;
    padding-right: 0 !important;
    margin-top: -20px;
    text-align: center;
  }

  @media (max-width: 1068px) {
    .contact-right-side button {
      margin-top: 20px;
    }
    .contact-right-side {
      width: 100% !important;
    }
  }
}

// recent work section //

@media (max-width: 450px) {
  .work-example-pic img {
    height: 250px !important;
  }
}
