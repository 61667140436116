.recent-work-wrapper {
  width: 80%;
  margin: 0 auto;
  font-family: $site-font;
}

.recent-work-wrapper h2 {
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 20px;
}

.recent-work-wrapper h2::after {
  content: "";
  height: 5px;
  width: 30px;
  background-color: black;
  position: absolute;
  transform: translate(-100px);
  margin-top: 40px;
}

.recent-work-wrapper .slick-dots li button:before {
  color: black;
  font-size: 1rem;
  margin-top: -20px;
}

.work-carousel {
  border: 1px solid grey;
  height: auto;
  margin-bottom: 40px;
  padding-top: 50px;
  padding-bottom: 20px;
}

.work-example-pic {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.work-example-pic img {
  width: 85%;
  margin: 0 auto;
  height: auto;
}

.work-example {
  text-align: center;
  margin: 0 auto;
  text-align: center;
  margin-top: -5px;
  display: flex;
  flex: wrap;
  width: 100%;
}

.work-example-one-container {
  display: flex;
}

.made-with {
  text-align: center;
  margin-top: -10px;
}

.work-example p {
  color: grey;
  margin-top: -5px;
}

.made-with-icons ul {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-top: 25px;
}

.made-with-icons li {
  list-style: none;
  padding: 7px;
}

.made-with-icons svg {
  font-size: 2rem;
}

.project-links ul {
  display: flex;
  text-decoration: underline;
  padding: 0;
  justify-content: center;
  margin-top: 20px;
}

.project-links a {
  text-decoration: none;
  color: black;
}

.project-links li {
  list-style: none;
}

.work-example h1 a {
  color: black;
}

.work-example a {
  color: black;
  text-decoration: none;
}
