.contact-wrapper {
  width: 75%;
  margin: 0 auto;
  font-family: $site-font;
}

.contact-container {
  display: flex;
}

.contact-text p {
  color: grey;
  font-size: 1rem;
}

.location-logo-container p {
  font-size: 0.9rem;
}

.email-logo-container p {
  font-size: 0.9rem;
}

.phone-logo-container p {
  font-size: 0.9rem;
}

// contact-left side

.contact-left-side {
  width: 50%;
  padding-right: 50px;
}

.contact-title h2 {
  text-transform: uppercase;
  text-align: center;
}

.contact-title h2::after {
  content: "";
  height: 5px;
  width: 30px;
  background-color: black;
  position: absolute;
  transform: translate(-65px);
  margin-top: 40px;
}

.contact-title h4 {
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.contact-logos {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}

.contact-logos svg {
  border: 2px solid black;
  font-size: 3.5rem;
  border-radius: 90px;
  padding: 10px;
}

.contact-logos p {
  color: grey;
}

.location-logo-container {
  display: flex;
  padding-bottom: 20px;
}

.location-logo {
  margin-right: 20px;
}

.email-logo-container {
  display: flex;
  padding-bottom: 20px;
}

.email-logo {
  margin-right: 20px;
}

.phone-logo-container {
  display: flex;
}

.phone-logo {
  margin-right: 20px;
}

// contact-right side //

.contact-right-side {
  width: 50%;
}

.contact-right-side button {
  background-color: black;
  color: white;
  width: auto;
  text-transform: uppercase;
  padding: 12.5px;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

input,
textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

iframe {
  width: 100%;
  height: 50vh;
  margin-top: 30px;
}

footer {
  background: black;
  color: white;
}

footer {
  text-align: center;
  font-family: $site-font;
  padding-top: 10px;
  padding-bottom: 10px;
}
