.socials-container {
  background-image: url(../../../assets/camping-night.jpg);
  height: clamp(50px, 755 / 1600 * 100vw, 220px);
  width: 100%;
  background-size: cover;
  position: relative;
  text-align: center;
  margin-top: -25px;
  justify-content: center;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.socials-container svg {
  color: white;
  font-size: 4.5rem;
  padding: 15px;
}
