.skills-container {
  width: 90%;
  margin: 0 auto;
  font-family: $site-font;
}

.skills-container h2 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.skills-container h2::after {
  content: "";
  height: 5px;
  width: 30px;
  background-color: black;
  position: absolute;
  transform: translate(-50px);
  margin-top: 40px;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills-container h4 {
  list-style: none;
  text-transform: uppercase;
}

.skills-title {
  text-align: center;
  border-top: solid grey 1px;
  padding: 15px;
  margin: 10px;
  width: 20%;
  transition: all 0.5s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.skills-title:hover {
  transform: scale(1.2);
  color: #0066cc;
}

.skills-title svg {
  font-size: 3.5rem;
}

.skills-title p {
  color: grey;
  border-top: 1px solid grey;
  padding-top: 15px;
}
